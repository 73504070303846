'use strict';
angular.module('cb.pricingapp-service', [])
	.factory('PricingAppService', ["LeadProfileService", function (LeadProfileService) {
		return {
			getQuoteRecOption: function (enquiry) {
				if (enquiry.QuoteRecOption1) {
					return 1;
				} else if (enquiry.QuoteRecOption2) {
					return 2;
				} else if (enquiry.QuoteRecOption3) {
					return 3;
				} else {
					return null;
				}
			},
			getConfigValue: function (key) {
				return LeadProfileService.getConfigValue(key).then(function (url) {
					return url;
				}, function (err) {
					return err;
				});
			}
		};
	}]);
